import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "@layout/layout"
import { pl, en } from "@content/pages/voluntary.yaml"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"

export default ({isEn}) => {
  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <HeaderSubpage isEn={isEn}/>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      {!isEn && (
        <section className="section wrapper">
          <div
            className="padding sasaboxbg text-center b-lazy  b-loaded"
            style={{
              backgroundImage: `url(${withPrefix(
                "images/voluntary/hero.png"
              )})`,
            }}
          >
            <div className="row collapse">
              <div className="large-12 columns text-center">
                <h1 style={{ color: "#ffffff" }}></h1>
              </div>
            </div>
          </div>
          <section
            className="news-text padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  Wolontariat w Fundacji SASA to przede wszystkim możliwość
                  zdobycia praktycznej wiedzy w zawodzie lekarza. Stawiamy na
                  ciągły rozwój naszych wolontariuszy – umożliwiając im wyjazd
                  na praktyki na Ukrainę, do Tanzanii czy podczas dodatkowych
                  warsztatów i szkoleń z lekarzami-praktykami.
                </p>
                <p>
                  Do programu rekrutujemy bezpośrednio wolontariuszy Fundacji
                  SASA! Dołącz do nas i weź udział w jednym z naszych programów!
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <a
                    href={withPrefix("images/voluntary/child.jpg")}
                    className="image-link"
                  >
                    <img
                      src={withPrefix("images/voluntary/child.jpg")}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <strong>Wymiana polsko-ukraińska</strong>
                </p>
                <p>
                  <span style={{ fontWeight: "400" }}>
                    Współpracujemy z dwoma szpitalami na Ukrainie – z Głównym
                    Klinicznym Szpitalem Wojskowym w Kijowie i Centralnym
                    Szpitalem Klinicznym &nbsp;“Ukrzaliznytsa” w Harkovie.
                    Dzięki dobrej współpracy umożliwiamy zarówno studentom
                    polskim jak i ukraińskim udział w wymianach. Jest to nasz
                    autorski program, który finansujemy dzięki wsparciu
                    darczyńców i współpracujących z nami szpitali. Staramy się,
                    aby każda nowa grupa polsko-ukraińska nawiązała ze sobą
                    dobry kontakt – oprócz edukacji ważne są również nowe,
                    ciekawe znajomości!{" "}
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <a
                    href={withPrefix("images/voluntary/surgery.jpg")}
                    className="image-link"
                  >
                    <img
                      src={withPrefix("images/voluntary/surgery.jpg")}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <span style={{ fontWeight: "400" }}>
                    Program polega na tygodniowym bądź dwutygodniowym wyjeździe
                    do renomowanych szpitali na Ukrainie, w których studenci
                    zdobywają praktyczne umiejętności na bloku operacyjnym,
                    asystując podczas zabiegów chirurgicznych, zaopatrując i
                    szyjąc rany oraz obserwując ciekawe przypadki z medycyny
                    urazowej. Grupa ukraińska przyjeżdżając do Polski,
                    &nbsp;uczestniczy przede wszystkim w &nbsp;zabiegach
                    transplantacyjnych i kardiochirurgicznych.
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <strong>Wolontariat w Tanzanii</strong>
                </p>
                <p>
                  <span style={{ fontWeight: "400" }}>
                    Od Tanzaniii wszystko się zaczęło… Wolontariat w Tanzanii, w
                    szpitalu St. Joseph (Św. Józefa), w mieście Moshi, jest
                    najstarszym programem realizowanym przez Fundację SASA.
                    Wyjazd do Tanzanii opiera się na wspieraniu tamtejszego
                    szpitala w zakresie medycznym. Wolontariusz podczas swojej
                    misji dostarcza i rozdysponowuje na miejscu sprzęt
                    przygotowany dla szpitala w ramach akcji SASA BOX. Jest też
                    odpowiedzialny za przeszkolenie personelu w zakresie jego
                    użytkowania. Kilkutygodniowy pobyt w St. Joseph jest okazją
                    do poznania tamtejszej medycyny i wykorzystania medycznej
                    wiedzy w praktyce. Wolontariusze obecni na miejscu mają za
                    zadanie wsparcie szpitala w zakresie leczenia chorych.{" "}
                  </span>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <a
                    href={withPrefix("images/voluntary/hospital.jpg")}
                    className="image-link"
                  >
                    <img
                      src={withPrefix("images/voluntary/hospital.jpg")}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <strong>Warsztaty i konferencje</strong>
                </p>
                <p>
                  <span style={{ fontWeight: "400" }}>
                    Staramy się ciągle rozwijać i poszerzać wiedzę naszych
                    wolontariuszy. Oferujemy udziały we współorganizowanych
                    przez nas konferencjach i warsztatach prowadzonych przez
                    współpracujących z Fundacją lekarzy. Wspólnie szkolimy się z
                    praktycznych aspektów ginekologii i położnictwa, chorób
                    zakaźnych, pediatrii. Podnosimy także swoje umiejętności
                    manualne podczas warsztatów z szycia chirurgicznego, czy
                    medycyny urazowej.{" "}
                  </span>
                </p>
              </div>
            </div>
          </section>
        </section>
      )}
      {isEn && (
        <section className="section wrapper">
          <div
            className="padding sasaboxbg text-center b-lazy  b-loaded"
            style={{
              backgroundImage: `url(${withPrefix(
                "images/voluntary/hero.png"
              )})`,
            }}
          >
            <div className="row collapse">
              <div className="large-12 columns text-center">
                <h1 style={{ color: "#ffffff" }}>Medical Volunteering</h1>
              </div>
            </div>
          </div>
          <section
            className="news-text padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  <a href="" className="image-link">
                    <img src="" alt="" />
                  </a>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="large-8 large-centered medium-12 small-12 columns">
                <p>
                  Volunteering at SASA Foundation is primarily an opportunity to
                  gain practical knowledge in the medical field. We focus on the
                  continuous development of our volunteers – allowing them to
                  practice in Ukraine, Tanzania or at additional workshops and
                  trainings with practitioners.
                </p>
              </div>
            </div>
          </section>
        </section>
      )}
    </Layout>
  )
}
